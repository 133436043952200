















import { defineComponent, ref } from "@vue/composition-api";
import ElTablePlus, { TableActionClickCallbackContext, TableApis, TableColumn, TableRow, TableSearchOptionItem } from "@/components/el-table-plus.vue";
import { FormStructureItem } from "@/components/el-form-plus/index.vue";
import { $post } from "@/http/axios";
import ElFormPlus from "../../components/el-form-plus/index.vue";
import { LEASE_STATUS_DICT, PUBLISH_STATUS_DICT } from "./common";
type DICT = {[key:number]:string}
export default defineComponent({
    name: "SpaceResource",
    components: { ElTablePlus, ElFormPlus },
    setup(props,ctx){
        /** 租赁状态字典 */
        const tableRef = ref();
        const apis:TableApis = {
            list:'/enterprisespaceresources/findResourcesDealList',
            save:'/enterprisespaceresources/editEnterpriseSpaceResources',
            detail:'/enterprisespaceresources/getEnterpriseSpaceResourcesById',
            remove:'/enterprisespaceresources/deleteEnterpriseSpaceResourcesById',
        }
        /**
         * 同意申请
         */
        const handleResolveApply = async (row:TableRow,context:TableActionClickCallbackContext)=>{
            await ctx.root.$confirm("确定通过该申请？","申请处理")
            let {message,success} =await $post('/enterprisespaceresources/dealEnterpriseSpaceResourcesApply',{
                applyId:row.applyId,
                resourcesId:row.resourcesId,
                status:1
            })
            if(success){
                ctx.root.$message.success(message);
                context.refresh()
            }else{
                ctx.root.$message.error(message);
            }
        }
         /**
         * 驳回申请
         */
        const handleRejectApply = async (row:TableRow,context:TableActionClickCallbackContext)=>{
            //@ts-ignore
            const {value:refuseReason} = await ctx.root.$prompt("请输入驳回原因","驳回申请");
            console.log(`驳回原因`,refuseReason)
            if(!refuseReason){
                ctx.root.$message.error("请输入驳回原因");
                return
            } 
            let {message,success} =await $post('/enterprisespaceresources/dealEnterpriseSpaceResourcesApply',{
                applyId:row.applyId,
                resourcesId:row.resourcesId,
                refuseReason,
                status:0
            })
            if(success){
                ctx.root.$message.success(message);
                context.refresh()
            }else{
                ctx.root.$message.error(message);
            }
        }
        const columns:TableColumn[] = [
            { label: "厂房地址",prop: "address",width:250},
            { label: "厂房面积(㎡)",prop: "idleArea"},
            { label: "申请企业",prop: "enterpriseName"},
            { label: "申请企业法人",prop: "legalPerson"},
            { label: "申请企业法人电话",prop: "legalPersonPhone",width:200},
            { label: "主营业务",prop: "mainBusiness"},
            { label: "生产工艺",prop: "productionProcess"},
            { label: "承诺目标值(万元)",prop: "targetValue" },
            { label: "承诺年税收(万元)",prop: "annualTax" },
            { label: "需求面积(㎡)",prop: "demandArea" },
            { label: "操作",type:'actions',fixed:'right',width:250,buttons:[
                {label: "同意",type:'primary',click:handleResolveApply},
                {label: "拒绝",type:'danger',click:handleRejectApply},
            ]},
        ]
        const formStructure:FormStructureItem[] = [
            { label:"申请企业",key:'landName',type:'text',span:8},
            { label:"申请企业法人",key:'publishStatus',type:'select',options:PUBLISH_STATUS_DICT},
            { label:"申请企业法人电话",key:'leaseStatus',type:'select',options:LEASE_STATUS_DICT},
            { label:"主营业务",key:'mainBusiness',type:'text'},
            { label:"承诺目标值(万元)",key:'targetValue',type:'text'},
            { label:"承诺年税收(万元)",key:'annualTax',type:'textarea',span:24},
            { label:"厂房地址",key:'address',type:'textarea',span:24},
            { label:"生产工艺",key:'生产工艺',type:'textarea',span:24},
        ]
         const searchOptions:TableSearchOptionItem[]=[
            { label:'资源地址',type:'text',key:'landName'},
            { label:'联系人',type:'text',key:'entContact'},
            { label:'联系方式',type:'text',key:'contactWay'},
        ]
       
        return {
            searchOptions,
            columns,tableRef,apis,
           
            }
    }
})
